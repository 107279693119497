<template>
  <div id="masterAttendance">
    <v-row>
      <v-col class="px-0 py-0" cols="12" sm="4" md="3">
        <div class="employees-list pt-3 d-flex justify-center">
          <v-btn icon @click="previousEmployee(selectedEmployee)">
            <v-icon> chevron_left</v-icon>
          </v-btn>
          <v-select
            v-if="employeeIndex >= 0 && employeesList.length > 0"
            :items="employeesList"
            v-model="selectedEmployee"
            item-text="name"
            item-value="id"
            @change="getEmployeeAttendance(selectedEmployee, '', 1)"
            solo
            dense
          >
          </v-select>
          <!-- <span
            class="main-color"
            v-if="employeeIndex >= 0 && employeesList.length > 0"
          >
            {{ employeesList[employeeIndex].name }}</span
          > -->
          <v-btn icon @click="nextEmployee(selectedEmployee)">
            <v-icon>chevron_right</v-icon>
          </v-btn>
        </div>
      </v-col>
      <v-col class="px-1 py-0" cols="12" sm="4" md="3">
        <!-- <v-radio-group
          v-model="is_vacation"
          row
          @change="getEmployeeAttendance"
          class="pt-3"
        >
          <v-radio label="Overtime" value="0"></v-radio>
          <v-radio label="Vacation" value="1"></v-radio>
        </v-radio-group> -->
        <v-row>
          <v-col cols="6">
            <v-checkbox
              v-model="is_vacation"
              :label="$t('Vacation')"
              value="1"
              @change="getEmployeeAttendance(selectedEmployee)"
            >
            </v-checkbox>
          </v-col>
          <v-col cols="6">
            <v-checkbox
              v-model="is_vacation"
              :label="$t('OverTime')"
              value="0"
              @change="getEmployeeAttendance(selectedEmployee)"
            >
            </v-checkbox>
          </v-col>
        </v-row>
      </v-col>

      <!-- <v-col class="px-0" cols="6" sm="4" md="3">
        <v-menu
          class="pt-3"
          offset-y
          :close-on-content-click="false"
          :nudge-width="300"
          offset-x
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark v-bind="attrs" v-on="on" icon>
              <i v-on="on" class="fas fa-filter main-color fa-lg"></i>
            </v-btn>
          </template>
          <v-list class="select px-4"> </v-list>
        </v-menu>
        <label class="search" for="inpt_search">
          <input
            id="inpt_search"
            type="text"
            @focus="inputFocus"
            @blur="inputBlur"
            v-model="search"
          />
        </label>
      </v-col> -->

      <v-col class="px-0 py-0" cols="6" sm="4" md="3">
        <div class="months-list pt-3 d-flex">
          <v-btn icon @click="previousMonth(selectedMonth)">
            <v-icon> chevron_left</v-icon>
          </v-btn>

          <v-select
            v-if="monthsIndex >= 0 && monthsList.length > 0"
            :items="monthsList"
            v-model="selectedMonth"
            item-text="month"
            item-value="id"
            @change="getEmployeeAttendance(selectedEmployee, selectedMonth)"
            solo
            dense
          >
          </v-select>
          <v-btn icon @click="nextMonth(selectedMonth)">
            <v-icon>chevron_right</v-icon>
          </v-btn>

          <!-- <span
            class="main-color"
            v-if="monthsIndex >= 0 && monthsList.length > 0"
          >
            {{ monthsList[monthsIndex].month }}</span
          > -->
        </div>
      </v-col>
      <v-col class="px-0 py-0" cols="6" sm="6" md="3">
        <v-row class="pt-5">
          <a
            big
            class="main-color px-2"
            @click="OpenDialogImport"
            :title="$t('Import File')"
          >
            <img src="../../assets/icons/upload.svg" width="30" height="30" />
          </a>
          <!-- <v-btn class="modal-btn-save ">Calculate Salary</v-btn> -->
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <!-- <v-col class="px-0 py-0" cols="6" sm="6" md="2">
        <v-btn class="modal-btn-save ">Calculate Salary</v-btn>
      </v-col> -->
    </v-row>
    <v-divider></v-divider>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="items"
          :items-per-page="15"
          class="elevation-1 level1"
          hide-default-footer
        >
          <template v-slot:item="{ item }">
            <!-- normal row -->
            <tr
              class="normal"
              v-if="
                item.is_overtime <= 0 &&
                  item.is_vacation <= 0 &&
                  item.is_weekend_vacation <= 0
              "
            >
              <td>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="#FFB400" dark v-bind="attrs" v-on="on">
                      label
                    </v-icon>
                  </template>
                  <span
                    >{{ $t("Job Title") }}: {{ item.job_title }}<br />
                    {{ $t("Monthly Salary") }}: {{ item.monthly_salary }} <br />
                    {{ $t("Allowances") }}: {{ item.allownces }}</span
                  >
                </v-tooltip>
                <span class="main-color">
                  {{ item.date }}
                </span>
              </td>
              <td v-if="editRowId != item.id">{{ item.employee_check_in }}</td>
              <td v-if="editRow == true && editRowId == item.id">
                <vue-timepicker
                  v-model="item.employee_check_in"
                  @change="
                    startTimeRule(
                      item.employee_check_in,
                      item.employee_check_out,
                      $t('Start time should be less than end time')
                    )
                  "
                >
                </vue-timepicker>

                <p class="error--text timeError" v-if="!startTimeValid">
                  Please enter a valid formate
                </p>
                <p class="error--text timeError" v-if="timeError">
                  {{ startTimeError }}
                </p>
              </td>
              <td v-if="editRowId != item.id">{{ item.employee_check_out }}</td>
              <td v-if="editRow == true && editRowId == item.id">
                <vue-timepicker
                  v-model="item.employee_check_out"
                  @change="
                    startTimeRule(
                      item.employee_check_in,
                      item.employee_check_out,
                      $t('End time should be more than start time')
                    )
                  "
                ></vue-timepicker>

                <p class="error--text timeError" v-if="!endTimeValid">
                  Please enter a valid formate
                </p>
                <p class="error--text timeError" v-if="timeError">
                  {{ timeError }}
                </p>
              </td>
              <td>{{ item.shift_name }}</td>
              <td>{{ item.shift_from }}</td>
              <td>{{ item.shift_to }}</td>
              <td>{{ item.hours }}</td>
              <td>{{ item.record_by }}</td>
              <td>
                <v-btn
                  icon
                  v-if="editRow == false"
                  @click="openEditRow(item.id, item)"
                >
                  <v-icon color="grey" :title="$t('Edit')">edit</v-icon>
                </v-btn>
                <v-btn
                  icon
                  v-if="editRow == true && editRowId == item.id"
                  @click="
                    saveRow(
                      item.id,
                      item.employee_check_in,
                      item.employee_check_out
                    )
                  "
                >
                  <v-icon color="grey" :title="$t('Save')">check_circle</v-icon>
                </v-btn>
                <v-btn
                  icon
                  v-if="editRow == true && editRowId == item.id"
                  @click="cancel(item)"
                >
                  <v-icon color="grey" :title="$t('Save')">cancel</v-icon>
                </v-btn>
              </td>
            </tr>
            <!-- overtime row -->
            <tr class="overtime" v-if="item.is_overtime > 0">
              <td>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="#FFB400" dark v-bind="attrs" v-on="on">
                      label
                    </v-icon>
                  </template>
                  <span
                    >{{ $t("Job Title") }}: {{ item.job_title }}<br />
                    {{ $t("Monthly Salary") }}: {{ item.monthly_salary }} <br />
                    {{ $t("Allowances") }}: {{ item.allownces }}</span
                  >
                </v-tooltip>

                <span class="white--text">
                  {{ item.date }}
                </span>
              </td>
              <td v-if="editRowId != item.id">{{ item.employee_check_in }}</td>
              <td v-if="editRow == true && editRowId == item.id">
                <vue-timepicker
                  v-model="item.employee_check_in"
                  @close="
                    startTimeRule(
                      item.employee_check_in,
                      item.employee_check_out,
                      $t('Start time should be less than end time')
                    )
                  "
                ></vue-timepicker>
                <p class="error--text timeError" v-if="!startTimeValid">
                  Please enter a valid formate
                </p>
                <p class="error--text timeError" v-if="timeError">
                  {{ startTimeError }}
                </p>
              </td>
              <td v-if="editRowId != item.id">{{ item.employee_check_out }}</td>
              <td v-if="editRow == true && editRowId == item.id">
                <vue-timepicker
                  v-model="item.employee_check_out"
                  @close="
                    startTimeRule(
                      item.employee_check_in,
                      item.employee_check_out,
                      $t('End time should be more than start time'),
                      1
                    )
                  "
                ></vue-timepicker>
                <p class="error--text timeError" v-if="!endTimeValid">
                  Please enter a valid formate
                </p>
                <p class="error--text timeError" v-if="timeError">
                  {{ timeError }}
                </p>
              </td>
              <td>{{ item.shift_name }}</td>
              <td>{{ item.shift_from }}</td>
              <td>{{ item.shift_to }}</td>
              <td>{{ item.hours }}</td>
              <td>{{ item.record_by }}</td>
              <td>
                <v-btn
                  icon
                  v-if="editRow == false"
                  @click="openEditRow(item.id, item)"
                >
                  <v-icon color="white" :title="$t('Edit')">edit</v-icon>
                </v-btn>
                <v-btn
                  icon
                  v-if="editRow == true && editRowId == item.id"
                  @click="
                    saveRow(
                      item.id,
                      item.employee_check_in,
                      item.employee_check_out
                    )
                  "
                >
                  <v-icon color="white" :title="$t('Save')"
                    >check_circle</v-icon
                  >
                </v-btn>

                <v-btn
                  icon
                  v-if="editRow == true && editRowId == item.id"
                  @click="cancel"
                >
                  <v-icon color="white" :title="$t('Cancel')">cancel</v-icon>
                </v-btn>
              </td>
            </tr>
            <tr
              class="vacation"
              v-if="item.is_vacation == 1 && item.is_weekend_vacation == 0"
            >
              <td>{{ item.date }}</td>
              <td colspan="7">
                <strong>{{ $t("VACATION - Annual Vacation") }}</strong>
              </td>
              <td></td>
            </tr>
            <tr
              class="weekend"
              v-if="item.is_vacation == 1 && item.is_weekend_vacation == 1"
            >
              <td>{{ item.date }}</td>
              <td colspan="7">
                <strong>{{ $t("Weekend") }}</strong>
              </td>
              <td></td>
            </tr>
          </template>
        </v-data-table>

        <div class="text-center pt-2">
          <v-pagination
            v-if="length > 0 && totalItems > 0"
            v-model="page"
            :length="length"
            :circle="true"
            :page="page"
            @input="GoNextPage"
            next-icon="mdi-menu-right"
            prev-icon="mdi-menu-left"
          >
          </v-pagination>
        </div>
      </v-col>
    </v-row>
    <importComponent
      :ImportDialog="ImportDialog"
      formTitle="Attendance"
      v-on:childImportToParent="onChildImportClick"
    ></importComponent>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
import importComponent from "../../components/modals/importComponent";
// timePicker
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";
export default {
  name: "MasterAttendance",
  components: { VueTimepicker, importComponent },

  data() {
    return {
      startTimeValid: true,
      endTimeValid: true,
      ImportDialog: false,
      headers: [
        // {
        //   text: 'name',
        //   align: 'start',
        //   sortable: false,
        //   value: 'name'
        // },
        { text: this.$i18n.t("Date"), value: "date", sortable: false },
        {
          text: this.$i18n.t("Check In"),
          value: "employee_check_in",
          sortable: false,
        },
        {
          text: this.$i18n.t("Check Out"),
          value: "employee_check_out",
          sortable: false,
        },
        {
          text: this.$i18n.t("Assigned Shift"),
          value: "shift_name",
          sortable: false,
        },
        {
          text: this.$i18n.t("Shift Check In"),
          value: "shift_from",
          sortable: false,
        },
        {
          text: this.$i18n.t("Shift Check Out"),
          value: "shift_to",
          sortable: false,
        },
        { text: this.$i18n.t("Hrs"), value: "hours", sortable: false },
        {
          text: this.$i18n.t("Record By"),
          value: "record_by",
          sortable: false,
        },
        { text: this.$i18n.t("Actions"), value: "actions", sortable: false },
      ],
      items: [],
      page: 1,
      length: 1,
      totalItems: 1,
      is_vacation: "",
      filter: {},
      search: "",
      employeesList: [],
      employeeIndex: 0,
      monthsList: [],
      monthsIndex: 0,
      selectedEmployee: "",
      selectedMonth: "",
      editRow: false,
      editRowId: "",
      timeError: "",
      startTimeError: "",
      oldCheckin: "",
      oldCheckout: "",
    };
  },

  watch: {
    // page: {
    //   handler() {
    //     if (this.selectedEmployee != "") {
    //       this.getEmployeeAttendance(this.selectedEmployee);
    //     } else {
    //       this.getEmployeeAttendance();
    //     }
    //   }
    // }
    // search: {
    //   handler() {
    //     this.page = 1;
    //     this.getEmployeeAttendance();
    //   },
    //   deep: true
    // }
  },
  methods: {
    GoNextPage() {
      if (this.selectedEmployee != "") {
        this.getEmployeeAttendance(this.selectedEmployee);
      } else {
        this.getEmployeeAttendance();
      }
    },
    OpenDialogImport() {
      this.ImportDialog = true;
      // console.log(this.ImportDialog);
    },
    onChildImportClick(value) {
      this.ImportDialog = value;
      this.getEmployeeAttendance(this.selectedEmployee);
    },
    getEmployeeAttendance(
      selectedEmployee = "",
      selectedMonth = "",
      page = null
    ) {
      let employeeId;
      if (selectedEmployee == "") {
        employeeId = this.employeesList[this.employeeIndex].id;
      } else {
        employeeId = selectedEmployee;
        this.selectedEmployee = selectedEmployee;
      }
      if (selectedMonth != "") {
        this.page = 1;
      }
      selectedMonth = this.selectedMonth;
      let monthId = selectedMonth;

      // if (this.monthsList.length == 0) {
      //   monthId = 1;
      // } else if (
      //   this.monthsList.length > 0 &&
      //   selectedMonth == "" &&
      //   monthId == ""
      // ) {
      //   monthId = this.monthsList[this.monthsIndex].id;
      // } else {
      //   monthId = selectedMonth;
      // }
      if (page != null) {
        this.page = page;
      }

      axios
        .get(
          this.getApiUrl +
            "/employee/getEmployeeAttendance/" +
            employeeId +
            "?page=" +
            this.page +
            "&search=" +
            this.search +
            "&is_vacation=" +
            this.is_vacation +
            "&month=" +
            monthId,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
              //the token is a variable which holds the token
            },
          }
        )
        .then((response) => {
          this.items = [];
          this.items = response.data.data.data;
          this.length = response.data.data.last_page;
          this.totalItems = response.data.data.total;
        });
    },
    previousEmployee(selectedEmployee) {
      let i = 0,
        selectedIndex;
      this.employeesList.forEach((employee) => {
        if (employee.id == selectedEmployee) {
          selectedIndex = i;
        }
        i++;
      });

      this.selectedEmployee = this.employeesList[
        selectedIndex - 1 >= 0 ? selectedIndex - 1 : 0
      ].id;
      this.getEmployeeAttendance(this.selectedEmployee, "", 1);

      // if (this.employeeIndex > 0) {
      //   this.employeeIndex -= 1;
      //    this.selectedEmployee = this.employeesList[this.employeeIndex].id;
      //   this.getEmployeeAttendance(this.selectedEmployee);
      // }
    },
    nextEmployee(selectedEmployee) {
      let i = 0,
        selectedIndex;
      this.employeesList.forEach((employee) => {
        if (employee.id == selectedEmployee) {
          selectedIndex = i;
        }
        i++;
      });

      this.selectedEmployee = this.employeesList[
        selectedIndex + 1 < this.employeesList.length
          ? selectedIndex + 1
          : selectedIndex
      ].id;
      this.getEmployeeAttendance(this.selectedEmployee, "", 1);

      // if (this.employeeIndex < this.employeesList.length - 1) {
      //   this.employeeIndex += 1;
      //    this.selectedEmployee = this.employeesList[this.employeeIndex].id;
      //   this.getEmployeeAttendance(this.selectedEmployee);
      // }
    },
    getAllEmployees() {
      axios
        .get(this.getApiUrl + "/employee/get_all_employees", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            //the token is a variable which holds the token
          },
        })
        .then((response) => {
          this.employeesList = response.data.data;
          this.selectedEmployee = this.employeesList[this.employeeIndex].id;
          this.getEmployeeAttendance();
        });
    },
    getAllMonths() {
      axios
        .get(this.getApiUrl + "/dashboard/student/GetMonthsForFilter", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            //the token is a variable which holds the token
          },
        })
        .then((response) => {
          this.monthsList = response.data.data;
          // this.selectedMonth = this.monthsList[this.monthsIndex].id;
          var d = new Date();
          this.selectedMonth = d.getMonth() + 1;
        });
    },
    previousMonth(selectedMonth) {
      let i = 0,
        selectedIndex;
      this.monthsList.forEach((month) => {
        if (month.id == selectedMonth) {
          selectedIndex = i;
        }
        i++;
      });

      this.selectedMonth = this.monthsList[
        selectedIndex - 1 >= 0 ? selectedIndex - 1 : 0
      ].id;
      this.getEmployeeAttendance("", this.selectedMonth);

      // if (this.monthsIndex > 0) {
      //   this.monthsIndex -= 1;
      //   this.selectedMonth = this.monthsList[this.monthsIndex].id;
      //   this.getEmployeeAttendance("", this.selectedMonth);
      // }
    },
    nextMonth(selectedMonth) {
      let i = 0,
        selectedIndex;
      this.monthsList.forEach((month) => {
        if (month.id == selectedMonth) {
          selectedIndex = i;
        }
        i++;
      });

      this.selectedMonth = this.monthsList[
        selectedIndex + 1 < this.monthsList.length
          ? selectedIndex + 1
          : selectedIndex
      ].id;
      this.getEmployeeAttendance("", this.selectedMonth);

      // if (this.monthsIndex < this.monthsList.length - 1) {
      //   this.monthsIndex += 1;
      //   this.selectedMonth = this.monthsList[this.monthsIndex].id;
      //   this.getEmployeeAttendance("", this.selectedMonth);
      // }
    },
    openEditRow(rowId, item) {
      this.oldCheckin = item.employee_check_in;
      this.oldCheckout = item.employee_check_out;
      this.editRow = true;
      this.editRowId = rowId;
      this.timeError = "";
      this.startTimeError = "";
    },
    saveRow(rowId, checkin, checkout) {
      this.startTimeRule(
        checkin,
        checkout,
        "Start time should be less than end time"
      );
      if (!this.timeError && this.startTimeValid && this.endTimeValid) {
        axios
          .post(
            this.getApiUrl + "/employee/attendance/update/" + rowId,
            {
              checkin: checkin,
              checkout: checkout,
            },
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            }
          )
          .then((response) => {
            this.oldCheckin = "";
            this.oldCheckout = "";
            window.location.reload();
          });

        this.editRow = false;
        this.editRowId = "";
        // this.getEmployeeAttendance();
      }
    },
    cancel(item) {
      item.employee_check_in = this.oldCheckin;
      item.employee_check_out = this.oldCheckout;
      this.editRow = false;
      this.editRowId = "";
      // this.getEmployeeAttendance();
    },
    startTimeRule(start, end, str) {
      this.checkDateValid(start, end, str);

      if (this.startTimeValid && this.endTimeValid) {
        var startTime = moment(start, "HH:mm");
        var endTime = moment(end, "HH:mm");
        startTime >= endTime ? (this.timeError = str) : (this.timeError = "");
        if (this.timeError != "") {
          this.startTimeError = "Start time should be less than end time";
        } else {
          this.startTimeError = "";
        }
        // console.log("error");
      }
    },

    checkDateValid(start, end, str) {
      /* check if have value or not start and end */
      if (start) {
        let startArr = start.split(":");
        // check start date
        isNaN(Number(startArr[0]))
          ? (this.startTimeValid = false)
          : (this.startTimeValid = true);
        isNaN(Number(startArr[1]))
          ? (this.startTimeValid = false)
          : (this.startTimeValid = true);
      } else {
        this.startTimeValid = false;
      }
      /* check if have value or not start and end */
      if (end) {
        let endArr = end.split(":");
        // check end date
        isNaN(Number(endArr[0]))
          ? (this.endTimeValid = false)
          : (this.endTimeValid = true);
        isNaN(Number(endArr[1]))
          ? (this.endTimeValid = false)
          : (this.endTimeValid = true);
      } else {
        this.endTimeValid = false;
      }
    },
  },
  mounted() {
    this.getAllMonths();
    this.getAllEmployees();

    // console.log(this.selectedMonth);
  },
};
</script>
<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_modals.scss";
// @import 'vue2-timepicker/dist/VueTimepicker.css';

.weekend {
  background-color: #e1e1e1 !important;
}

.vacation {
  background-color: #e1e1e1 !important;
}

.timeError {
  font-size: 12px;
}
</style>
